import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import accountAction from 'redux/actions/account';
import appAction from 'redux/actions/app';
import { stateType } from 'types/types';
import { ReactComponent as NotificationIcon } from '../../../../assets/image/account/notificationIcon.svg';
import { ReactComponent as IconClock } from '../../../../assets/image/common/clock-outline.svg';

import './styles.scss';

const NotificationContainer: any = (notifications: any) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const notificationRef = useRef<HTMLDivElement>(null);
  const amount: any = useSelector((state: any) => state.account.checkNotification);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (notificationRef.current && !notificationRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [notificationRef]);

  const readedNotifications = notifications?.notifications?.items?.filter((el: any) => el.read !== true);
  const beforeNotifications = notifications?.notifications?.items?.filter((el: any) => el.read === true);
  const countNotif = readedNotifications?.length >= 4 ? 4 : 2;
  const checkCountOfNotif = readedNotifications?.length <= 4 ? 1 : -1;

  const url: any = useSelector((state: stateType) => state.app.url, shallowEqual);
  const dispatch = useDispatch();

  const openUrl = (item: any) => {
    dispatch(accountAction.readNotifications({ forumNotificationIds: [item.id] }));
    const searchString = '?grouport_token=';
    const startIndex = url?.indexOf(searchString);
    const resultString = url?.substring(startIndex);
    dispatch(appAction.connectToForum());
    window.open(`${item.referenceUrl}${resultString}`, '_blank');
  };

  return (
    <div className="notification">
      <div className="notification-button-container" onClick={() => setShowDropdown(true)}>
        <NotificationIcon />
        <div className="notification-button-container__unread-meassage-button">
          <div className="notification-button-container__unread-meassage-button-text">
            {amount?.amountUnreadNotifications}
          </div>
        </div>
      </div>
      {showDropdown && (
        <div className="notification-menu" ref={notificationRef}>
          <div className="notification-menu__header">
            <div className="notification-menu__header-title">Notifications</div>
            <div className="notification-menu__header-subtitle">
              You have {amount?.amountUnreadNotifications} unread messages
            </div>
          </div>
          <div className="notification-menu__header-line" />
          <div className="notification-menu__section">
            {readedNotifications?.length !== 0 && <div className="notification-menu__section-title">UNREAD</div>}
            {readedNotifications?.map((item: any, index: number) => {
              if (index <= countNotif) {
                return (
                  <div key={index} className="notification-menu__new-notif">
                    <div className="notification-menu__section-message" onClick={() => openUrl(item)}>
                      {item.message}
                    </div>
                    <div>
                      <div className="notification-menu__section-time">
                        <IconClock />
                        <div className="notification-menu__section-time-text">
                          {moment(item.createdAt).format('DD MMM YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div className="notification-menu__section">
            {beforeNotifications?.length !== 0 && checkCountOfNotif !== -1 && (
              <div className="notification-menu__section-title">READ</div>
            )}
            {beforeNotifications?.map((item: any, index: number) => {
              if (index <= checkCountOfNotif) {
                return (
                  <div key={index} className="notification-menu__new-notif" style={{ background: '#fff' }}>
                    <div className="notification-menu__section-message" onClick={() => openUrl(item)}>
                      {item.message}
                    </div>
                    <div>
                      <div className="notification-menu__section-time">
                        <IconClock />
                        <div className="notification-menu__section-time-text">
                          {moment(item.createdAt).format('DD MMM YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div className="notification-menu__header-line" />
          <button
            className="btn-light btn-light_cancel notification-menu__button"
            onClick={() => (window.location.href = '/notifications')}
          >
            View all
          </button>
        </div>
      )}
    </div>
  );
};

export default NotificationContainer;
