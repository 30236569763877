/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { CardElement } from '@stripe/react-stripe-js';
import showError from 'redux/sagas/showError';
// actions
import stripeAction from '../../redux/actions/stripe';
import accountAction from '../../redux/actions/account';

const stripeCullMethods = {
  confirmCardPayment: async (
    stripe: any,
    clientSecret: any,
    subscriptionId: any,
    elements: any,
    setProcessing: any,
    setError: any,
    dispatch: any,
    name: string,
  ) => {
    let paymentMethod = '';
    try {
      let confirmData = {};
      if (clientSecret) {
        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement)!,
            billing_details: {
              name,
            },
          },
        });
        confirmData = { paymentIntentId: result.paymentIntent.id };
      } else {
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement)!,
          billing_details: {
            name,
          },
        });
        paymentMethod = result.paymentMethod.id;
      }

      dispatch(
        stripeAction.confirm(
          clientSecret ? confirmData : { stripeSubscriptionId: subscriptionId, stripePaymentMethod: paymentMethod },
        ),
      );
    } catch (e: any) {
      showError();
      dispatch(stripeAction.loader(false));
      setError(e.message);
    } finally {
      setProcessing(false);
    }
  },

  createPaymentMethod: async (
    stripe: any,
    elements: any,
    setPaymentMethod: any,
    setProcessing: any,
    setError: any,
    setVisible: any,
    dispatch: any,
    name: string,
    triggerAction = true,
  ) => {
    try {
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)!,
        billing_details: {
          name,
        },
      });
      const request: any = {
        stripePaymentMethodId: result.paymentMethod.id,
        token: localStorage.getItem('edit_token'),
        refreshToken: localStorage.getItem('refresh_token'),
        type: 'payment-method',
      };
      setPaymentMethod(result.paymentMethod);

      if (triggerAction) {
        dispatch(accountAction.edit(request, () => setVisible(false)));
      }
      dispatch(stripeAction.loader(false));
    } catch (e: any) {
      /* notification.error({
        message: 'Stripe Error',
        description: e.message,
        onClick: () => {
          console.log('Notification Clicked!');
        },
      }); */
      showError();
      dispatch(stripeAction.loader(false));
      setError(e.error);
    } finally {
      setProcessing(false);
    }
  },
};

export default stripeCullMethods;
