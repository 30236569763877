import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { userProfile } from '../../../redux/reducers/user';
import { useSelector } from 'react-redux';
import { stateType } from '../../../types/types';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const { user, meetingType, setIsAudioRemotelyLocked, setIsVideoRemotelyLocked, setHostIdentity } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams<{ URLRoomName: string }>();
  const [step, setStep] = useState(Steps.roomNameStep);

  const [name, setName] = useState<string>(user?.displayName || 'Test');
  const [roomName, setRoomName] = useState<string>('Test Room');

  const displayRoomName = meetingType != 'webinar' ? roomName + ' Group' : roomName;

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    setIsAudioRemotelyLocked(false);
    setIsVideoRemotelyLocked(false);
    setHostIdentity('');
  });

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName]);

  const userProfile = useSelector((state: stateType) => state.user.profile);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch((error) => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      const roomType = meetingType == 'webinar' ? 'webinar' : 'room';
      window.history.replaceState(
        null,
        '',
        window.encodeURI(`/${roomType}/${roomName}${window.location.search || ''}`),
      );
    }
    setStep(Steps.deviceSelectionStep);
  };

  return (
    <>
      <div style={{ width: '100%', height: 1, backgroundColor: '#c6dae3', position: 'absolute', top: 60 }}></div>
      <IntroContainer>
        <MediaErrorSnackbar error={mediaError} />
        {step === Steps.roomNameStep && (
          <RoomNameScreen
            name={name}
            roomName={displayRoomName}
            setName={setName}
            setRoomName={setRoomName}
            handleSubmit={handleSubmit}
          />
        )}
        {step === Steps.deviceSelectionStep && (
          <DeviceSelectionScreen name={name} roomName={displayRoomName} setStep={setStep} />
        )}
      </IntroContainer>
    </>
  );
}
