import { useEffect } from 'react';
import { DataTrack as IDataTrack, RemoteDataTrack } from 'twilio-video';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import { shallowEqual, useSelector } from 'react-redux';
import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useFilesContext from '../../../hooks/useFilesContext/useFilesContext';
import useUserDataContext from '../../../hooks/useUserDataContext/useUserDataContext';

interface DataTrackProps {
  track: IDataTrack;
}

export default function DataTrack({ track }: DataTrackProps) {
  const [isAudioEnabled, toggleAudioEnabled, toggleAudioEnabledRemotely] = useLocalAudioToggle();
  const [isVideoEnabled, toggleVideoEnabled, toggleVideoEnabledRemotely] = useLocalVideoToggle();
  const { room, localTracks } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const role = useSelector((state: any) => state.user.profile.role, shallowEqual);
  const {
    showWaitingRoom,
    hideWaitingRoom,
    participantsToShow,
    setParticipantsToShow,
    setHostIdentity,
  } = useAppState();
  const { connectPrivate } = useChatContext();
  const { loadFileList } = useFilesContext();
  const { getUserName, setUserName, setUserIsHost } = useUserDataContext();

  useEffect(() => {
    track.removeAllListeners('message');
    console.log('adding data track listener');
    track.on('message', (jsonData, track: RemoteDataTrack) => {
      const data = JSON.parse(jsonData);
      console.log('message received', data, localParticipant.sid, track.name);
      if (localParticipant.sid == data.recipient) {
        switch (data.command) {
          case 'toggle-audio':
            toggleAudioEnabledRemotely();
            break;
          case 'toggle-video':
            toggleVideoEnabledRemotely();
            break;
          case 'connect-to-private-chat':
            const endpoint = process.env.REACT_APP_API_URL + 'twilio/chat_token';
            const JWTtoken = localStorage.getItem('token');
            fetch(endpoint, {
              method: 'POST',
              headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${JWTtoken}`,
              },
              body: JSON.stringify({
                user_identity: localParticipant.identity,
                conversation_friendly_name: data.conversationFriendlyName,
                conversation_unique_name: data.conversationUniqueName,
              }),
            })
              .then((response) => response.json())
              .then((responseData) => {
                connectPrivate(responseData.token, data.identity, data.conversationUniqueName);
              });
            break;
          default:
            break;
        }
      } else {
        switch (data.command) {
          case 'ping-for-host':
            console.log('ping-for-host');
            if (role == 'ADMIN' || role == 'THERAPIST') {
              localTracks
                .filter((track) => track.kind == 'data')
                .forEach((track) => {
                  //@ts-ignore
                  track.send(JSON.stringify({ command: 'host-present', identity: localParticipant.identity }));
                });
            }
            break;
          case 'host-present':
            console.log('host-present');
            if (showWaitingRoom) {
              hideWaitingRoom();
            }
            if (!participantsToShow.includes(data.identity)) {
              const tmp = participantsToShow;
              participantsToShow.push(data.identity);
              setParticipantsToShow(tmp);
            }
            setHostIdentity(data.identity);
            setUserIsHost(data.identity, true);
            break;
          case 'file-uploaded':
            loadFileList();
            break;
          case 'host-disconnected':
            room!.disconnect();
            setTimeout(() => {
              window.location.href = 'https://' + window.location.hostname;
            }, 500);
            break;
          case 'show-me': {
            if (!participantsToShow.includes(data.identity)) {
              const tmp = participantsToShow;
              participantsToShow.push(data.identity);
              setParticipantsToShow(tmp);
            }
            break;
          }
          case 'my-name-is': {
            console.log('my-name-is', data.identity, data.name);
            setUserName(data.identity, data.name);
            break;
          }
          case 'send-name': {
            console.log('send-name');
            localTracks
              .filter((track) => track.kind == 'data')
              .forEach((track) => {
                //@ts-ignore
                track.send(
                  JSON.stringify({
                    command: 'my-name-is',
                    identity: localParticipant.identity,
                    name: getUserName(localParticipant.identity),
                  }),
                );
              });
            break;
          }
          default:
            break;
        }
      }
    });
    return () => {
      track.removeAllListeners('message');
    };
  }, [track, toggleAudioEnabledRemotely, toggleVideoEnabledRemotely]);

  return null;
}
