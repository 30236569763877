import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { NestedValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useWindowResize } from 'beautiful-react-hooks';
import _ from 'lodash';
// actions
import consultationAction from '../../../../redux/actions/consultation';
// components
import FormSelect, { FormSearchSelect } from '../../../molecules/inputsBlocks/select';
import SubmitButton from '../../../atoms/form/Button';
import DateOfBirthInput from '../../../molecules/inputsBlocks/consultation/DateOfBirthInput';
import PhonesInput from '../../../molecules/inputsBlocks/consultation/PhonesInput';
import ReasonsSelect from '../../../molecules/inputsBlocks/select/ReasonsSelect';
// helpers
import { mapping } from '../../../../api/mapping/request/consultation';
import { setFormErrors } from '../../../../tools/helpFunctions';
import { consultationSchema } from '../../../../tools/validations';
// types
import { IErrors } from '../../../../types/actions/common-types';
// style
import '../style.scss';
import './style.scss';
// icons
import Icon from '@ant-design/icons';
import { ReactComponent as IconPointer } from '../../../../assets/image/forms/pointer.svg';
import { stateType } from '../../../../types/types';
import { useHistory } from 'react-router-dom';

interface FormData {
  reasons: NestedValue<number[]>;
  topics: number;
  statuses?: number;
  genders: number;
  state?: number;
  countries?: number;
  date: string;
  phone: string;
}

const ConsultationForm: React.FC = () => {
  const [liveUS, setLiveUS] = useState(true);
  const [width, setWidth] = useState<number>(window.innerWidth);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<FormData>({ mode: 'onBlur', resolver: yupResolver(consultationSchema) });

  const consultationData: any = useSelector(
    (state: stateType) => state.consultation_form.consultationData,
    shallowEqual,
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (data: FormData) => {
    const setErrors = (errors: Array<IErrors>) => setFormErrors(setError, errors);
    dispatch(consultationAction.submit(mapping.buildRequest(data), setErrors, goToRegistration));
    dispatch(consultationAction.setData(mapping.buildRequest(data)));
  };

  const goToRegistration = () => {
    history.push('/go-to-series', { loading: true });
  };

  const watchReasons = watch('reasons');
  const watchTopic = watch('topics');

  const switchAction = () => {
    setLiveUS((liveUS) => !liveUS);
    setValue('countries', undefined);
    setValue('state', undefined);
  };

  useEffect(() => {
    clearErrors(liveUS ? 'countries' : 'state');
  }, [liveUS]);

  useEffect(() => {
    if (!_.isEmpty(consultationData)) {
      setValue('reasons', consultationData.reasonIds);
      setValue('topics', consultationData.groupTopicId);
      setValue('statuses', consultationData.relationshipStatusId);
      setValue('genders', consultationData.genderId);
      setValue('state', consultationData.stateId);
      setValue('countries', consultationData.countryId);
      setValue('date', consultationData.birthday);
      setValue('phone', `+1${consultationData?.phone}`);

      !consultationData.stateId && switchAction();
    }
  }, [consultationData]);

  useWindowResize(() => {
    setWidth(window.innerWidth);
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-consultation validation-form">
      <ReasonsSelect
        errors={errors.reasons?.message}
        register={register}
        control={control}
        defaultValue={consultationData?.reasonIds}
      />
      <FormSelect
        name="topics"
        label={
          width > 375
            ? 'Which group do you think would best suit your needs?'
            : 'Which group would best suit your needs?'
        }
        errors={errors.topics?.message}
        control={control}
        placeholder="Select your top choice"
        defaultValue={consultationData?.groupTopicId}
        optionsName="topicsExtended"
      />

      {(watchTopic === 7 || _.filter(watchReasons, (el) => el === 11).length > 0) && (
        <FormSelect
          name="statuses"
          label="What’s your relationship status?"
          errors={errors.statuses?.message}
          control={control}
          placeholder="Status"
          defaultValue={consultationData?.relationshipStatusId}
        />
      )}
      <div className="form-consultation__row">
        <div className="form-consultation__column">
          <DateOfBirthInput errors={errors.date?.message} control={control} defaultValue={consultationData?.birthday} />
        </div>
        <div className="form-consultation__column">
          <FormSelect
            name="genders"
            label="Gender"
            errors={errors.genders?.message}
            control={control}
            placeholder="Select Gender"
            defaultValue={consultationData?.genderId}
          />
        </div>
      </div>

      {liveUS ? (
        <FormSearchSelect
          name="state"
          label="What’s your state of residence?"
          errors={errors.state?.message}
          control={control}
          placeholder={
            <>
              <Icon component={IconPointer} style={{ fontSize: '15px', marginBottom: '2px', marginRight: '5px' }} />
              {'Select State'}
            </>
          }
          filterOption={(input: string, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          defaultValue={consultationData?.stateId}
        />
      ) : (
        <FormSearchSelect
          name="countries"
          label="What’s your country of residence?"
          errors={errors.countries?.message}
          control={control}
          placeholder={
            <>
              <Icon component={IconPointer} style={{ fontSize: '15px', marginBottom: '2px', marginRight: '5px' }} />
              {'Select Country'}
            </>
          }
          filterOption={(input: string, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          defaultValue={consultationData?.countryId}
        />
      )}
      <div className="form-country-checker" data-testid="country-checker" onClick={switchAction}>
        {liveUS ? 'I live outside of the US' : 'I live in the US'}
      </div>
      <PhonesInput
        errors={errors.phone?.message}
        control={control}
        defaultValue={consultationData?.phone ? `+1${consultationData?.phone}` : ''}
      />

      <SubmitButton name="Find my Group" />
    </form>
  );
};

export default ConsultationForm;
