import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import { shallowEqual, useSelector } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      backgroundColor: '#E19D89',
      '&:hover': {
        backgroundColor: 'rgba(249, 112, 102, 1)',
      },
    },
  }),
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room, localTracks } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const role = useSelector((state: any) => state.user.profile.role, shallowEqual);
  const [showButton, setShowButton] = useState(false);

  const handleClick = useCallback(() => {
    const level = localParticipant.networkQualityStats?.level;
    const endpoint = process.env.REACT_APP_API_URL + 'twilio/end_session';
    const JWTtoken = localStorage.getItem('token');

    fetch(endpoint, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${JWTtoken}`,
      },
      body: JSON.stringify({
        room_name: room?.name,
        level: level,
      }),
    });

    if (role == 'ADMIN' || role == 'THERAPIST') {
      localTracks
        .filter((track) => track.kind == 'data')
        .forEach((track) => {
          //@ts-ignore
          track.send(JSON.stringify({ command: 'host-disconnected' }));
        });
    }
    room!.disconnect();
  }, [room]);

  useEffect(() => {
    if (role == 'ADMIN' || role == 'THERAPIST') {
      setShowButton(true);
    }
  }, [role]);

  return showButton ? (
    <Button onClick={handleClick} className={clsx(classes.button, props.className)} data-cy-disconnect>
      End Session
    </Button>
  ) : null;
}
